// Actions
const UPDATE_SHARABLE_LINK = 'updateSharableLink'
const UPDATE_SHARE_STATE_PARAMS = 'updateShareStateParams'

// Mutations
const SET_SHARABLE_LINK = 'setSharableLink'
const SET_SHARE_STATE_PARAMS = 'setShareStateParams'

// Namespace
const NAMESPACE = 'share'

// Cache
const CACHE_NAME = 'shareCache'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    UPDATE_SHARABLE_LINK,
    UPDATE_SHARE_STATE_PARAMS
  },
  mutation: {
    SET_SHARABLE_LINK,
    SET_SHARE_STATE_PARAMS
  },
  namespace: NAMESPACE,
  cacheName: CACHE_NAME,
  withNamespace
}
