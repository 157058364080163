var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader",style:({
    'font-size': `${_vm.fontSize}rem`,
    width: `${_vm.size.width}px`,
    height: `${_vm.size.height}px`,
    'border-bottom-color': _vm.backdropColor,
    'border-right-color': _vm.backdropColor,
    'border-top-color': _vm.backdropColor,
    'border-left-color': _vm.accentColor
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }