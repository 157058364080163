import { ActionContext } from 'vuex'
import constants from './-constants'
import { UpdateSharePayload } from '~/store/share/-types'

const { UPDATE_SHARABLE_LINK, UPDATE_SHARE_STATE_PARAMS } = constants.action
const { SET_SHARABLE_LINK, SET_SHARE_STATE_PARAMS } = constants.mutation

export default {
  [UPDATE_SHARE_STATE_PARAMS](
    { commit, state, rootState }: ActionContext<any, any>,
    payload: UpdateSharePayload
  ) {
    commit(SET_SHARE_STATE_PARAMS, payload)
  },

  [UPDATE_SHARABLE_LINK]({ commit, state, rootState }: ActionContext<any, any>, payload: any) {
    commit(SET_SHARABLE_LINK, payload)
  }
}
