export const startsWithCapital = (str: string) => {
  return str.charAt(0) === str.charAt(0).toUpperCase()
}

export const convertUCWordsOrCamelCaseToDashCase = (str: string) => {
  const conversion = str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
  const conversionLength = conversion.length + 1
  return startsWithCapital(str) ? conversion.substring(1, conversionLength) : conversion
}

export const smoothScrollToTargetId = (target: string) => {
  document.querySelector(target)?.scrollIntoView({
    behavior: 'smooth'
  })
}

export const smoothScrollSidebarToTargetId = (target: string) => {
  document.querySelector(target)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest'
  })
}

export const textTemplate = {
  replace: function (template: string, variables: { literal: string; value: any; type: string }[]) {
    let output = template
    variables.forEach((variable) => {
      let value
      switch (variable.type) {
        case 'Date':
          value = new Date(variable.value).toDateString()
          break
        case 'Array':
          value = variable.value.toString()
          break
        default:
          value = variable.value
          break
      }
      output = output.replaceAll(`%${variable.literal}%`, variable.value)
    })
    return output
  }
}

export const hashTextInSHA256 = async (message: string) => {
  const textAsArrayBuffer = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', textAsArrayBuffer) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  return hashArray.map((b: number) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
}

const normalizeObject = (object: Record<any, any>): Record<any, any> => {
  if (typeof object !== 'object' || object === null) {
    // Return non-objects (primitives) as-is
    return object
  }

  if (Array.isArray(object)) {
    // If it's an array, process each element
    return object.map(normalizeObject)
  }

  // If it's an object, sort its keys and recursively normalize values
  return Object.keys(object)
    .sort() // Sort keys alphabetically
    .reduce((acc: any, key: any) => {
      acc[key] = normalizeObject(object[key]) // Recursively normalize each value
      return acc
    }, {})
}

export const generateDeterministicItem = async (object: Record<any, any>) => {
  const normalizedObj = normalizeObject(object)
  const jsonString = JSON.stringify(normalizedObj)
  const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(jsonString))
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte: number) => byte.toString(16).padStart(2, '0'))
    .join('')
}

export const getElementHalfWidth = (target: string) => {
  const sidebarElement = document.querySelector(target)
  if (sidebarElement instanceof HTMLElement) {
    const sidebarElementWidth = sidebarElement.offsetWidth
    return sidebarElementWidth / 1.5
  }
  return 0
}

export const orderQueryParamsByGivenOrderList = (
  queryParams: any,
  orderList: string[] = [
    'langCode',
    'phase',
    'building',
    'floor',
    'space',
    'type',
    'orientation',
    'status',
    'amenities',
    'pinCategory',
    'pin'
  ]
) => {
  // Filter and sort the queryParams based on the orderList
  const orderedQueryParams = orderList.filter((param: string) => param in queryParams)

  // Add any remaining query params not in the orderList
  const remainingQueryParams = Object.keys(queryParams).filter(
    (param: string) => !orderList.includes(param)
  )
  const allQueryParams = [...orderedQueryParams, ...remainingQueryParams]

  const orderedQuery: Record<string, string> = {}
  allQueryParams.forEach((param) => {
    if (param in queryParams) {
      orderedQuery[param] = queryParams[param] // Add key-value pair in the specified order
    }
  })
  return orderedQuery
}
