import { ActionContext } from 'vuex'
import constants from './-constants'

const {
  CHANGE_LANGUAGE,
  TOGGLE_SIDEBAR,
  CHANGE_SIDEBAR_STATE,
  CLOSE_SIDEBAR_STATE,
  TOGGLE_FILTER,
  TOGGLE_FILTER_V2,
  SHOW_FILTER,
  TOGGLE_INFO,
  CLOSE_FILTER,
  CHANGE_FILTER_STATE,
  CHANGE_FILTER_V2_STATE,
  LOAD_METADATA,
  CHANGE_PAGE_LOADING_STATE,
  TOGGLE_MOBILE_AVAILABILITY_MENU,
  CHANGE_MOBILE_AVAILABILITY_MENU,
  CHANGE_DOCUMENT_READY_STATE,
  TOGGLE_SIDEBAR_MOBILE,
  CHANGE_SIDEBAR_MOBILE_STATE,
  CLOSE_SIDEBAR_MOBILE_STATE,
  TOGGLE_AVAILABILITY_MENU,
  TOGGLE_CHOOSE_VIEW_MENU,
  TOGGLE_CHOOSE_PHASE_MENU,
  CLOSE_CHOOSE_VIEW_MENU,
  CLOSE_CHOOSE_PHASE_MENU,
  CHANGE_AVAILABILITY_MENU,
  TOGGLE_COLLAPSED_MENU,
  CHANGE_COLLAPSED_MENU,
  TOGGLE_SHOW_LEASED_SPACES,
  TOGGLE_INTERACTIVE_MOUSE_ENABLED
} = constants.action
const {
  SET_LANGUAGE,
  SET_METADATA,
  SET_SIDEBAR_STATE,
  SET_FILTER_STATE,
  SET_FILTER_V2_STATE,
  SET_PAGE_LOADING_STATE,
  SET_MOBILE_AVAILABILITY_MENU,
  SET_DOCUMENT_READY_STATE,
  SET_SIDEBAR_MOBILE_STATE,
  SET_AVAILABILITY_MENU,
  SET_CHOOSE_VIEW_MENU,
  SET_CHOOSE_PHASE_MENU,
  SET_COLLAPSED_MENU,
  SET_SHOW_LEASED_SPACES,
  SET_INTERACTIVE_MOUSE_ENABLED
} = constants.mutation

export default {
  [CHANGE_LANGUAGE]({ commit, state }: ActionContext<any, any>, langCode: any) {
    commit(SET_LANGUAGE, langCode)
  },
  [TOGGLE_SIDEBAR]({ commit, state }: ActionContext<any, any>) {
    commit(SET_SIDEBAR_STATE, !state.sidebarOpen)
  },
  [TOGGLE_SIDEBAR_MOBILE]({ commit, state }: ActionContext<any, any>) {
    commit(SET_SIDEBAR_MOBILE_STATE, !state.isSidebarMobileOpen)
  },
  [TOGGLE_COLLAPSED_MENU]({ commit, state }: ActionContext<any, any>) {
    commit(SET_COLLAPSED_MENU, !state.isCollapsedMenu)
  },
  [CHANGE_COLLAPSED_MENU]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_COLLAPSED_MENU, payload)
  },
  [TOGGLE_FILTER]({ commit, state }: ActionContext<any, any>) {
    commit(SET_FILTER_STATE, !state.filterOpen)
  },
  [TOGGLE_FILTER_V2]({ commit, state }: ActionContext<any, any>) {
    commit(SET_FILTER_V2_STATE, !state.isFilterOpen)
  },
  [SHOW_FILTER]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_FILTER_STATE, true)
  },
  [TOGGLE_INFO]({ commit, state }: ActionContext<any, any>) {
    commit(SET_FILTER_STATE, !state.filterOpen)
  },
  [CLOSE_FILTER]({ commit }: ActionContext<any, any>) {
    commit(SET_FILTER_STATE, false)
  },
  [CLOSE_CHOOSE_VIEW_MENU]({ commit }: ActionContext<any, any>) {
    commit(SET_CHOOSE_VIEW_MENU, false)
  },
  [CLOSE_CHOOSE_PHASE_MENU]({ commit }: ActionContext<any, any>) {
    commit(SET_CHOOSE_PHASE_MENU, false)
  },
  [TOGGLE_AVAILABILITY_MENU]({ commit, state }: ActionContext<any, any>) {
    commit(SET_AVAILABILITY_MENU, !state.isAvailabilityMenuOpen)
  },
  [TOGGLE_CHOOSE_VIEW_MENU]({ commit, state }: ActionContext<any, any>) {
    commit(SET_CHOOSE_VIEW_MENU, !state.isChooseViewBuildingsOpen)
  },
  [TOGGLE_CHOOSE_PHASE_MENU]({ commit, state }: ActionContext<any, any>) {
    commit(SET_CHOOSE_PHASE_MENU, !state.isChoosePhaseOpen)
  },
  [CHANGE_AVAILABILITY_MENU]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_AVAILABILITY_MENU, payload)
  },
  [TOGGLE_MOBILE_AVAILABILITY_MENU]({ commit, state }: ActionContext<any, any>) {
    commit(SET_MOBILE_AVAILABILITY_MENU, !state.mobileAvailabilityMenuOpen)
  },
  [CHANGE_MOBILE_AVAILABILITY_MENU]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_MOBILE_AVAILABILITY_MENU, payload)
  },
  [CHANGE_SIDEBAR_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SIDEBAR_STATE, payload)
  },
  [CLOSE_SIDEBAR_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SIDEBAR_STATE, false)
  },
  [CHANGE_SIDEBAR_MOBILE_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SIDEBAR_MOBILE_STATE, payload)
  },
  [CLOSE_SIDEBAR_MOBILE_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SIDEBAR_MOBILE_STATE, false)
  },
  [CHANGE_FILTER_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_FILTER_STATE, payload)
  },
  [CHANGE_FILTER_V2_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_FILTER_V2_STATE, payload)
  },
  async [LOAD_METADATA]({ commit, rootState }: ActionContext<any, any>) {
    let response

    // @ts-ignore / TODO - rethink the eslint rule
    const apiUrl = this.$config.API_URL

    let url = 'https://' + apiUrl
    if (apiUrl.includes('localhost')) {
      url = 'http://' + apiUrl
    }

    // @ts-ignore / TODO - rethink the eslint rule
    response = await this.$axios.$get(`${url}/landlord/config`)

    const config = response.result

    const { projectConfig } = config
    const buildingSection = projectConfig.sections.find((s: any) => s.type === 'Building')
    let isV2 = false
    if (buildingSection) {
      isV2 = buildingSection.variant === 'v2'
    }

    // @ts-ignore / TODO - rethink the eslint rule
    response = await this.$axios.$get(`${url}/${isV2 ? 'v2/' : ''}landlord/details`)
    const details = response.result
    config.landlordDetails = details
    // if (payload.general.customJavascriptEnabled) {
    //   payload.general.customJavascript = ''
    // }
    // console.log(rootState)
    commit(SET_METADATA, { metaData: config, rootState })
    return { config, details }
  },
  [CHANGE_PAGE_LOADING_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_PAGE_LOADING_STATE, payload)
  },
  [CHANGE_DOCUMENT_READY_STATE]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_DOCUMENT_READY_STATE, payload)
  },
  [TOGGLE_SHOW_LEASED_SPACES]({ commit }: ActionContext<any, any>, payload: boolean) {
    commit(SET_SHOW_LEASED_SPACES, payload)
  },
  [TOGGLE_INTERACTIVE_MOUSE_ENABLED](
    { commit }: ActionContext<any, any>,
    payload: boolean = false
  ) {
    commit(SET_INTERACTIVE_MOUSE_ENABLED, payload)
  }
}
