import baseConstants from '~/store/base/-constants'
import shareConstants from '~/store/share/-constants'

export default async function ({ app: { i18n, $cookiz }, route, store }) {
  const locale = !i18n.locales.includes(route.query.langCode)
    ? store.state?.base?.meta?.generalConfig?.defaultLocale ?? i18n.defaultLocale
    : route.query.langCode
  if (i18n.locales.includes(route.query.langCode)) {
    await store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_LANGUAGE), locale)
    await store.dispatch(
      shareConstants.withNamespace(shareConstants.action.UPDATE_SHARE_STATE_PARAMS),
      { activeLang: locale }
    )
    await i18n.setLocale(locale)
    return
  }
  if (!$cookiz.get('language-cookie') && !i18n.locales.includes(route.query.langCode)) {
    $cookiz.set('language-cookie', locale)
    await store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_LANGUAGE), locale)
    await store.dispatch(
      shareConstants.withNamespace(shareConstants.action.UPDATE_SHARE_STATE_PARAMS),
      { activeLang: locale }
    )
    await i18n.setLocale(locale)
  }
}
