import { ShareState, UpdateSharePayload } from './-types'
import constants from './-constants'

const { SET_SHARABLE_LINK, SET_SHARE_STATE_PARAMS } = constants.mutation

export default {
  [SET_SHARE_STATE_PARAMS](state: ShareState, payload: UpdateSharePayload) {
    if (typeof payload.activeLang !== 'undefined') {
      state.activeLang = payload.activeLang
    }
    if (typeof payload.activePin !== 'undefined') {
      state.activePin = payload.activePin
    }
    if (typeof payload.activePinCategory !== 'undefined') {
      state.activePinCategory = payload.activePinCategory
    }
    if (typeof payload.activeSpace !== 'undefined') {
      state.activeSpace = payload.activeSpace
    }
  },

  [SET_SHARABLE_LINK](state: ShareState, payload: any) {
    // Convert the payload object into a JSON string
    const payloadString = JSON.stringify(payload)

    // Encode the payload string to make it URL-safe
    const encodedPayload = encodeURIComponent(payloadString)

    // Append the encoded payload to the base URL as a query parameter
    state.sharableLink = `?payload=${encodedPayload}`
    console.log('sharableLink', state.sharableLink)
  }
}
