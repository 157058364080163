class BaseApiModule {
  public readonly baseUrl: string

  constructor(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url
    }
    this.baseUrl = url || 'http://localhost:3000'
    if (url.includes('localhost')) {
      this.baseUrl = 'http://localhost:3000'
    }
  }

  camelToUnderscore(stringValue: string) {
    return stringValue.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
}

export default BaseApiModule
