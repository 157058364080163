import { render, staticRenderFns } from "./MoleculeProjectFiltersHeader.vue?vue&type=template&id=668acd2e&"
import script from "./MoleculeProjectFiltersHeader.vue?vue&type=script&lang=js&"
export * from "./MoleculeProjectFiltersHeader.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeProjectFiltersHeader.vue?vue&type=style&index=0&id=668acd2e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeProjectFiltersHeader.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2FbuildingV2%2FMoleculeProjectFiltersHeader.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingV2MoleculeAvailabilityFilterStatistics: require('/app/components/molecules/project/buildingV2/MoleculeAvailabilityFilterStatistics.vue').default,AtomsCommonAtomSimpleButton: require('/app/components/atoms/common/AtomSimpleButton.vue').default})
